
import React, { useEffect, useRef, useState } from 'react';
import Title from '../Title';
import '../../styles/global.css'
import swal from 'sweetalert';
import {getMonthlyReport } from '../../services/apiServices';
import moment from 'moment';
import { generateDynamicUrduNote } from '../../utilis/constant';
import ExportMonthlyReport from '../monthlyReportExcel';
import ExportPDFReport from '../monthlyReportPdf';
import UrduPDF from '../urdu';
import GeneratePDF from '../urdu';

const MonthlyReport = () => {
    const [allReport, setAllReport] = useState([]);
    const hasFetched = useRef(false);
    const [note, setNote] = useState('');
    const [from, setFrom] = useState('2024-11');
    const [to, setTo] = useState(null);
    const [urduNote, setUrduNote] = useState('');

    const [headers] = useState([ 
        {title: '' , dataKey: "", align: "text-center",key:1,},
        {title: 'Total_Unit' , dataKey: "total_unit", align: "text-center",key:2},
        {title: 'Prev_Unit' , dataKey: "previous_unit", align: "text-center",key:3},
        {title: 'Units' , dataKey: "unit", align: "text-center",key:4},
        {title: 'Rate' , dataKey: "unit_rate", align: "text-center",key:5},
        {title: 'Amount' , dataKey: "unit_amount", align: "text-center",key:6},
        {title: 'Motor_Bill' , dataKey: "motor_bill", align: "text-center",key:7},
        {title: 'Prev_Bal' , dataKey: "previous_balance", align: "text-center",key:8},
        {title: 'Total' , dataKey: "total_amount", align: "text-center",key:9},
    ]);

    useEffect(() => {
        if (hasFetched.current) return;
        hasFetched.current = true;
        fetchMonthlyReport();
        const urduNote = generateDynamicUrduNote();
        console.log(urduNote);
        setUrduNote(urduNote)
    }, [urduNote]);

    const fetchMonthlyReport = async () => {
        const data ={
            from : from,
            to : to,
        }
        let result = await getMonthlyReport({data});
        console.log('monthly', result)
        if (result.error) {
            swal({ text: result.error, icon: "warning", dangerMode: true });
        } else {
            if(result.data.data){
                setAllReport(result.data.data);
            }
        }
    }
    return (
        <>
        <div className='px-lg-4 px-2 mb-5'>
        <Title title={"Monthly Report"} back={true}  />
        <div>
        <div className='d-flex'>
        <ExportMonthlyReport 
        xlsxfilename={'UnitMonthlyReport'}
        data={allReport}
        urduText={urduNote}
        />
        <ExportPDFReport
        data={allReport}
        headers={headers}
        urduText={urduNote}
            />
        </div>
        {allReport.map((v, index) =>
        <div className='table-responsive px-1' key={index}>
        <div className='row text-center acc-label mb-0 py-2 border p-bg px-3'>
            <div className='text-left size-22 acc-label w-25'>FLAT NO: {v.name}</div>
            <div className='text-center w-50 acc-label size-22'>{v.pid === 1 ? 'Ahmad Plaza' : ''}</div>
            <div className='text-right size-22 acc-label w-25'>{v.motor_report ? moment(v.motor_report.month).format('D MMMM YYYY') : ''}</div>
        </div>
         <table id="eduapp" className="table table-bordered bg-white mb-5 ">
            <thead >
                <tr className='table-primary'>
                {headers.map(head => (
                <th id={head.dataKey} className={head.align} key={head.key}>
                {head.title}</th> ))}
                </tr>
            </thead>
            <tbody>
            {v.report.map((value,i) => 
                <tr key={value.id}>
                <td className='text-center'>{moment(value.month).format('MMM')}</td>
                <td className='text-center'>{value.total_unit}</td>
                <td className='text-center'>{value.previous_unit}</td>
                <td className='text-center'>{value.present_unit}</td>
                <td className='text-center'>{value.unit_rate}</td>
                <td className='text-center'>{value.unit_amount}</td>
                <td className='text-center'>{value.motor_bill}</td>
                <td className='text-center'>{value.previous_balance}</td>
                <td className='text-center'>{value.total_amount}</td>
                </tr>)}
                {v.motor_report &&
                <tr>
                <td className='bold text-center acc-label'>{v.motor_report && 'Motor Bill'}</td>
                 <td className='text-center bold acc-label'>{v.motor_report && v.motor_report.total_unit}</td> 
                 <td className='text-center bold acc-label'>{v.motor_report && v.motor_report.previous_unit}</td> 
                 <td className='text-center bold acc-label'>{v.motor_report && v.motor_report.unit}</td> 
                 <td className='text-center bold acc-label'>{v.motor_report && v.motor_report.unit_rate}</td> 
                 <td className='text-center bold acc-label'>{v.motor_report && v.motor_report.unit_amount}</td>  
                 <td className='text-center bold acc-label'>{v.motor_report && v.motor_report.division}</td> 
                 <td></td>   
                </tr>}
            </tbody>
         </table>
        </div>)}
        </div>
       
        </div>
        </>
        )

}
export default MonthlyReport